.section4 {
    background-color: #00ACDC;
    padding: 0px 2% 100px 2%; 
    color: #fff;

  }
  
  .section4-text {
    margin-bottom: 20px;
    font-size: 22px;
  }

  .section4-title {
    margin-bottom:50px;
  }
  
  .section4-button {
    font-weight: bold;
    background-color: #f0ad4e; 
    border: none;
    padding: 10px 20px;
  }
  
